<template>
  <v-autocomplete
    ref="field"
    :disabled="disabled"
    v-model="localValue"
    :items="persons"
    item-text="name"
    item-value="id"
    return-object
    :label="label"
    hide-no-data
    hide-details
    :class="{
      'hidden-sm-and-down': !inputVisible,
      'ml-2': true,
      'mr-3': $vuetify.breakpoint.smAndUp,
    }"
    style="max-width: 420px"
    v-bind="inputProps"
  >
    <v-list-item-content slot="item" slot-scope="data">
      <v-list-item-title>{{ data.item.name }}</v-list-item-title>
      <v-list-item-subtitle>{{
        [
          [data.item.zip_code, data.item.city].filter((e) => e).join(" "),
          data.item.email,
        ]
          .filter((e) => e)
          .join(" / ")
      }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'PersonAutocomplete',
  props: {
    value: {
      validator: prop => {
        return typeof prop === 'object' || prop === null || prop === undefined
      },
      required: true,
    },
    persons: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: 'Person auswählen ...',
    },
    inputVisible: {
      type: Boolean,
      default: true,
    },
    inputProps: {
      type: Object,
      default() {
        return {
          solo: true,
          flat: true,
        }
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localValue: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) },
    },
  },
  methods: {
    blur() { this.$refs.field.blur() },
  },
}
</script>

<style>
</style>
