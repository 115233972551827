<template>
  <v-btn
    tile
    elevation="0"
    outlined
    large
    v-bind="bindings"
    :href="href"
    :to="to"
    :disabled="disabled"
    @click="() => (props.click ? props.click() : null)"
  >
    <v-icon left>{{ props.icon }}</v-icon>
    {{ title }}
  </v-btn>
</template>

<script setup>
import { defineProps, computed, useAttrs } from 'vue'

const props = defineProps({
  title: {
    type: String | Function,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean | Function,
    default: false,
  },
  href: {
    type: String | Function | null,
    default: null,
  },
  to: {
    type: String | Function | null,
    default: null,
  },
  click: {
    type: Function | null,
    default: undefined,
  },
})

const attrs = useAttrs()

function callOrValue(props, name) {
  // console.log(props, name, props[name], typeof props[name] === 'function')
  return () => {
    if (typeof props[name] === 'function') {
      return props[name]()
    }
    return props[name]
  }
}

// eslint-disable-next-line no-unused-vars
const title = computed(callOrValue(props, 'title'))

// eslint-disable-next-line no-unused-vars
const disabled = computed(callOrValue(props, 'disabled'))

// eslint-disable-next-line no-unused-vars
const href = computed(callOrValue(props, 'href'))

// eslint-disable-next-line no-unused-vars
const to = computed(callOrValue(props, 'to'))

// eslint-disable-next-line no-unused-vars
const bindings = computed(() => {
  // console.log(JSON.stringify(attrs))
  return {
    target: attrs.target,
  }
})


</script>
