<template>
  <div>
    <div v-if="status === null">
      <p>Der Anlass wurde...</p>
      <v-radio-group v-model="status" :mandatory="false" :disabled="disabled">
        <template v-for="(description, key) of status_map">
          <v-radio
            :key="key"
            :label="'... ' + description"
            :value="key"
          ></v-radio>
        </template>
      </v-radio-group>
    </div>
    <div v-else>
      <div class="mb-2">
        <span class="mr-1">Der Anlass wurde {{ status_map[status] }}</span>
        <v-btn icon @click="status = null" :disabled="disabled">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <v-textarea
        v-model="report"
        outlined
        :label="reportLabel"
        hide-details
        :disabled="disabled"
      ></v-textarea>
      <p v-if="reporter" class="mt-2 text-caption">
        Zuletzt bearbeiten von {{ reporter }}.
      </p>
    </div>
    <div class="mt-3">
      <v-btn
        outlined
        color="grey"
        class="mr-5"
        @click.stop="reset"
        :disabled="disabled"
        >Zurücksetzen</v-btn
      >
      <v-btn outlined color="success" @click.stop="save" :disabled="disabled"
        >Speichern</v-btn
      >
    </div>
  </div>
</template>

<script>
import eventService from '@/services/event'
import personService from '@/services/person'

import { createNotification } from '@/utils'

const STATUS_MAP = {
  finished: 'durchgeführt wie geplant.',
  finished_diff: 'durchgeführt, aber mit anderem Programm.',
  canceled: 'abgesagt.',
}

export default {
  name: 'EventReportForm',
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      status: null,
      status_map: STATUS_MAP,
      report: '',
      reporter: '',
      reporter_id: null,
    }
  },
  computed: {
    reportLabel() {
      return this.status !== 'canceled' ? 'Tourenbericht' : 'Begründung'
    },
  },
  watch: {
    reporter_id: function (val) {
      if (val !== null && val !== undefined) {
        personService.get(val).then(data => {
          this.reporter = data.name
        })
      } else {
        this.reporter = ''
      }
    },
  },
  methods: {
    reset() {
      this.status = null
      this.report = ''
      this.save()
    },
    update(data) {
      this.report = data.report
      this.status = data.status
      this.reporter_id = data.report_by
    },
    save() {
      eventService.saveReport(this.eventId, this.status, this.report)
        .then(data => {
          this.update(data)
          createNotification('Tourenbericht gespeichert!', 'success')
        })
    },
    load() {
      eventService.getReport(this.eventId)
        .then(data => this.update(data))
    },
  },
  mounted() {
    this.load()
  },
}
</script>
