<template>
  <v-app-bar flat color="grey lighten-3" dense :id="`tb${_uid}`">
    <v-toolbar-title
      :class="{ 'hidden-sm-and-down': inputVisible }"
      class="subheading"
      >{{ title }}</v-toolbar-title
    >
    <v-spacer :class="{ 'hidden-sm-and-down': inputVisible }" />
    <person-autocomplete
      ref="sel"
      v-model="selected"
      :disabled="disabled"
      :persons="source"
      :label="`${title} hinzufügen...`"
      :input-visible="inputVisible"
      :input-props="{ dense: true, solo: true, flat: true }"
    />
    <v-btn
      icon
      dark
      color="success"
      @click="showInput"
      class="hidden-md-and-up"
      v-if="!inputVisible"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import PersonAutocomplete from '@/components/person/PersonAutocomplete'

export default {
  name: 'EventParticipationToolbar',
  components: { PersonAutocomplete },
  props: {
    title: {
      default: '',
      type: String,
    },
    source: {
      default: () => [],
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      selected: null,
      inputVisible: false,
    }
  },
  computed: {},
  watch: {
    selected(newVal) {
      if (newVal === null) {
        return
      }
      this.$emit('itemclick', newVal)
      setTimeout(() => {
        this.$refs.sel.blur()
        this.selected = null
      }, 500)
    },
  },
  methods: {
    showInput() {
      this.inputVisible = true
      // this.$scrollTo(`#tb${this._uid}`)
      // this.$nextTick(this.$refs.sel.focus)
    },
    onFocus() {
      this.suggestionsVisible = true
    },
    onBlur() {
      setTimeout(() => {
        this.suggestionsVisible = false
        this.inputVisible = false
      }, 200)
    },
    onItemClick(item) {
      this.$emit('itemclick', item)
      this.input = ''
    },
  },
}
</script>
